@charset 'UTF-8';
@import "/Users/huongalive/Website/vhost/adva/src/scss/utility/_media-queries.scss";
@import "/Users/huongalive/Website/vhost/adva/src/scss/utility/_mixin.scss";

_:-ms-fullscreen, :root body { overflow-x: hidden; }
// Loading
.loading {
	background: -moz-linear-gradient(top, #2ea3ed 0%, #1f81e1 42.36%, #166bd9 70.94%, #2389e4 94.09%, #2ea3ed 100%);
    background: -webkit-linear-gradient(top, #2ea3ed 0%, #1f81e1 42.36%, #166bd9 70.94%, #2389e4 94.09%, #2ea3ed 100%);
    background: linear-gradient(to bottom,#2ea3ed 0%, #1f81e1 42.36%, #166bd9 70.94%, #2389e4 94.09%, #2ea3ed 100%);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 1;
	z-index: 1000000;
  * {
    backface-visibility: hidden;
  }
  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    @include transform_c(translate(-50%,-50%));
    width: 100%;
    padding: 0 20px;

    img {
      display: block;
      margin: 0 auto;
      width: 180px;
    }
    &--count {
      @include brandon;
      @include font-size(100);
      font-weight: bold;
      color: #fff;
      text-align: center;
      display: block;
      padding-left: 10px;
    }
  }
}
body.pace-done {
  .loading {
    animation: fadee 1s ease;
    -webkit-animation:fadee 1s ease;
    visibility: hidden;
  }
}
@keyframes fadee{
  0% {
    left: 0;
    visibility: visible;
  }
  100% {
    left: 100%;
    visibility: hidden;
  }
}
// end Loading

#top:not(.fix-header) {
	.wrapnav {
		nav {
			ul {
				> li {
					> a {
						color: #fff;
					}
					&.dropdown {
						&:HOVER {
							> a {
								color: #2EA3ED;
							}
						}//hover
					}//dropdown
				}//li
			}//ul
		}//nav
	}//wrapnav
}

.mainimg {	
	height: 100vh;
	background: url(../../img/top/img_main.jpg) center center;
	background-size: cover;
	position: relative;
	overflow: hidden;
	@include SP {
		background: url(../../img/top/img_mainsp.jpg) center center;
		background-size: cover;
	}
	#moving {
		display: none;
		&.moving {
			display: block;
		}
	}
	.moving {
	  position: absolute;
	  top: 50%;
      left: 50%;
      @include transform_c(translate(-50%,-50%));
      color: #fff;
      font-weight: bold;
      text-align: center;
      transition: all .1s ease;
      animation: movetxt 1s;
  	  -webkit-animation: movetxt 1s;
  	  width: fit-content;
  	  white-space: nowrap;
  	  @include SP {
  	  	width: 86%;
  	  }
      &__maintxt {
      	@include font-size(65);
      	font-weight: bold;
      	line-height: 1;
      	margin-bottom: 75px;
      	letter-spacing: 0.15em;
      	transition: all .2s ease;
	    animation: droptxt 2s;
	  	-webkit-animation: droptxt 2s;
	  	overflow: hidden;
      	@include SP {
      		@include font-size(40);
      		letter-spacing: 0.15em;
      		line-height: 1.5;
      		margin-bottom: 50px;
      	}
      	@include IP5 {
      		font-size: 34px;
      	}	
      }
      
      &__smltxt {
      	@include brandon;
      	@include font-size(26);
      	letter-spacing: 0.15em;
      	position: relative;
      	&:before {
		    background: #fff;
		    @include absolute(100%,4px,-30px,'','',0);
		    transition: all .2s ease;
		    animation: moveline 2s;
		  	-webkit-animation: moveline 2s;
		    
      	}
      	@include SP {
      		@include font-size(16);
      		letter-spacing: 0.15em;
      		line-height: 1.5;
      	}
      	@include IP5 {
      		font-size: 14px;
      	}
      }
	} //moving
	.scrolldown {
		@include brandon;
		@include font-size(14);
		letter-spacing: 0.1em;
		line-height: 1;
		color:#fff;
		font-weight: bold;
		position: absolute;
		left: 30px;
		bottom: 22px;
		z-index: 999;
		width: 100%;
		@include SP {
			left: 20px;
		}
		&:before {
			@include absolute(1px,94px,-104px,'','',2px); 
			border-left: 1px #fff solid;
			@include SP {
				height: 63px;
				top: -74px;
			}
		}
		&__point {
			position: relative;
			left: 3px;
			top: -94px;
			transition: all .6s ease;
			-webkit-animation: mover 1.6s ease-out infinite;
			animation: mover 1.6s ease-out infinite;
			@include SP {
				-webkit-animation: moversp 1.6s ease-out infinite;
				animation: moversp 1.6s ease-out infinite;
			}
			@include SP {
				top: -63px;
			}
			&:before{
				background: #ed338f;
				@include absolute(7px,7px,-7px,-3px,'','');
				border-radius: 50%;
				transition: all .6s ease;
			 	z-index: 99;
			}
			&:after{
			    background: rgba(255,255,255,.2);
			    @include absolute(7px,7px,-7px,-3px,'',-4px);
			    border-radius: 50%;
			    transition: all .6s ease;
			    -webkit-animation: scroll-point-zoom 2.6s ease-out infinite;
			    animation: scroll-point-zoom 2.6s ease-out infinite;
			    z-index: 90;
			}
		}
	}
	@-webkit-keyframes moveline {
		0% {
			width: 0;
	    }
	    20% {
	    	width: 0;
	    }
	    40% {
	    	width: 0;
	    }
	    70% {
	    }
	    100% {
	    	width: 100%;
	    }
	}
	@keyframes moveline {
		0% {
			width: 0;
	    }
	    20% {
	    	width: 0;
	    }
	    40% {
	    	width: 0;
	    }
	    70% {
	    }
	    100% {
	    	width: 100%;
	    }
	}
	@keyframes droptxt {
		0% {
			width: 30%;
	    }
	    100% {
	    	width: 100%;
	    }
	}
	@-webkit-keyframes droptxt {
		0% {
			width: 30%;
	    }
	    100% {
	    	width: 100%;
	    }
	}
	@-webkit-keyframes movetxt {
	    0% {
	        margin-left: 30px;
	        //opacity: 0;
	    }
	    20% {
	        //opacity: 0.3;
	    }
	    40% {
	        //opacity: 0.5;
	    }
	    70% {
	        //opacity: 0.7;
	    }
	    100% {
	        //opacity: 1;
	        margin-left: 0px;
	    }
	}
	@keyframes movetxt {
		0% {
	        margin-left: 30px;
	        //opacity: 0;
	    }
	    20% {
	        //opacity: 0.3;
	    }
	    40% {
	        //opacity: 0.5;
	    }
	    70% {
	        //opacity: 0.7;
	    }
	    100% {
	        //opacity: 1;
	        margin-left: 0px;
	    }
	}
	@-webkit-keyframes mover {
	    0% {
	        opacity: 1;
	        top: -104px;
	    }
	    20% {
	        opacity: 1;
	    }
	    40% {
	        opacity: 1;
	    }
	    70% {
	        opacity: 1;
	    }
	    100% {
	        opacity: 0;
	        top: 0px;
	    }
	}
	@keyframes mover {
		0% {
	        opacity: 1;
	        top: -104px;
	    }
	    20% {
	        opacity: 1;
	    }
	    40% {
	        opacity: 1;
	    }
	    70% {
	        opacity: 1;
	    }
	    100% {
	        opacity: 0;
	        top: 0px;
	    }
	}
	@include SP {
		@-webkit-keyframes moversp {
		    0% {
		        opacity: 1;
		        top: -74px;
		    }
		    20% {
		        opacity: 1;
		    }
		    40% {
		        opacity: 1;
		    }
		    70% {
		        opacity: 1;
		    }
		    100% {
		        opacity: 0;
		        top: 0px;
		    }
		}
		@keyframes moversp {
			0% {
		        opacity: 1;
		        top: -74px;
		    }
		    20% {
		        opacity: 1;
		    }
		    40% {
		        opacity: 1;
		    }
		    70% {
		        opacity: 1;
		    }
		    100% {
		        opacity: 0;
		        top: 0px;
		    }
		}
	}
	@-webkit-keyframes scroll-point-zoom {
	    0% {
	        opacity: 1;
	        -webkit-transform: scale(1);
	        -moz-transform: scale(1);
	        -ms-transform: scale(1);
	        -o-transform: scale(1);
	        transform: scale(1);
	    }
	    20% {
	        opacity: 1;
	    }
	    40% {
	        opacity: 0;
	        -webkit-transform: scale(4);
	        -moz-transform: scale(4);
	        -ms-transform: scale(4);
	        -o-transform: scale(4);
	        transform: scale(4);
	    }
	    70% {
	        opacity: 0;
	    }
	    100% {
	        opacity: 0;
	        -webkit-transform: scale(4);
	        -moz-transform: scale(4);
	        -ms-transform: scale(4);
	        -o-transform: scale(4);
	        transform: scale(4);
	    }
	}
	@keyframes scroll-point-zoom {
	    0% {
	        opacity: 1;
	        -webkit-transform: scale(1);
	        -moz-transform: scale(1);
	        -ms-transform: scale(1);
	        -o-transform: scale(1);
	        transform: scale(1);
	    }
	    20% {
	        opacity: 1;
	    }
	    40% {
	        opacity: 0;
	        -webkit-transform: scale(4);
	        -moz-transform: scale(4);
	        -ms-transform: scale(4);
	        -o-transform: scale(4);
	        transform: scale(4);
	    }
	    70% {
	        opacity: 0;
	    }
	    100% {
	        opacity: 0;
	        -webkit-transform: scale(4);
	        -moz-transform: scale(4);
	        -ms-transform: scale(4);
	        -o-transform: scale(4);
	        transform: scale(4);
	    }
	}
}
.philosophy {
	position: relative;
	&:before {
		@include gradient-adva;
    	@include absolute(calc(800px + (100% - 800px)/2 ),460px,-70px,'',0,0);
    	border-radius: 0px 20px 20px 0px;
    	z-index: 100;
    	@include SP {
    		width: calc(100% - 75px);
    		top:-60px;
    	}
	}
	&:after {
		@include gradient-adva;
    	@include absolute(calc(800px + (100% - 800px)/2 ),313px,'',0,-70px,'');
    	border-radius: 0 0 0 20px;
    	z-index: 100;
    	@include SP {
    		width: calc(100% - 75px);
    		bottom: -35px;
    		height: 370px;
    	}
	}
	.container {
		padding-top: 180px;
		padding-bottom: 30px;
		border-radius: 20px 0 20px 0;
		background: #fff;
		position: relative;
		z-index: 101;
		@include SP {
			margin-left: 5px;
			margin-right: 5px;
			padding-top: 150px;
			padding-bottom: 20px;
		}
	}
	&__txtblue {
		color: #2ea3ed;
		@include font-size(30);
		font-weight: 500;
		text-align: center;
		position: absolute;
		width: 100%;
		z-index: 104;
		top: 75px;
		@include SP {
			@include font-size(22);
			top: 40px;
			font-weight: bold;
		}
		&:after {
			@include absolute('','',-80px,0,'','');
			content: "PHILOSOPHY";
			@include brandon;
			@include font-size(148);
			font-weight: bold;
			letter-spacing: 0.1em;
			color: #f2faff;
			line-height: 1.2;
			text-align: right;
			z-index: -1;
			@include ipad {
				letter-spacing: 0;
			}	
			@include SP {
				@include font-size(46);
				top: -30px;
			}
			@include IP5 {
				font-size: 39px;
			}	
		}
	}
	p {
		@include PC {
			text-align: center;
			margin: 38px 0;
		}
		@include SP {
			margin: 30px 0;
		}
		&:last-child {
			margin-top: 40px;
		}
	}
}

.imgtop {
	background: url(../../img/top/img_top01.jpg) center; 
	background-size: cover;
	@include PC {
		background-attachment: fixed;
	}	
}

.solution {
	background: #F2FAFF;
	padding: 90px 0 160px;
	@include SP {
		padding: 60px 0 95px;	
	}
	&__head {
		text-align: center;
		.txt {
			@include brandon;
			font-weight: bold;
			@include font-size(54);
			letter-spacing: 0.1em;
			color: #2ea3ed;
			line-height: 1;
			@include SP {
				@include font-size(34);
			}
			@include IP5 {
				font-size: 30px;
			}	
		}
		.head {
			@include font-size(20);
			font-weight: 500;
			margin-top: 5px;
			@include SP {
				@include font-size(16);
			}
		}
	}
	&__txthead {
		@include PC {
			padding: 45px 10%;
			letter-spacing: 0;
			text-align: center;
		}
		@include SP {
			padding-top: 30px;
			padding-bottom: 30px;
		}
	}
	&__list {
		counter-reset: my-awesome-counter;
		margin-bottom: 35px;
		@include SP {
			@include font-size(14);
			margin-bottom: 30px;
		}
		li {
			border-radius: 15px;
			background: #fff;
			@include PC {
				width: 47.8%;
				margin-bottom: 30px;
				padding: 45px 35px;
			}
			@include SP {
				margin-bottom: 15px;
				padding: 25px 25px;
			}
			h3 {
				counter-increment: my-awesome-counter;
				color: #2EA3ED;
				@include font-size(20);
				font-weight: 500;
				position: relative;
				padding-left: 70px;
				margin-bottom: 35px;
				@include SP {
					@include font-size(16);
					margin-bottom: 5px;
					padding-left: 50px;
				}
				@include IP5 {
					font-size: 12px;
					padding-left: 40px;
				}
				&:before {
					@include absolute(23px,1px,50%,0,'',''); 
					border-top: 1px #2EA3ED solid;
					left: -35px;
					@include SP {
						width: 12px;
						left: -25px;
					}
				}	
				&:after {
				  content: counter(my-awesome-counter,decimal-leading-zero);
				  @include brandon;
				  font-weight: bold;
				  @include font-size(49);
				  line-height: 1;
				  position: absolute;
				  left: 0;
				  top: -8px;
				  @include SP {
				  	@include font-size(36);
				  }
				  @include IP5 {
				  	font-size: 30px;
				  }	
				}
			}  
		}
	}
}
.news-top {
	position: relative;
	margin-bottom: 55px;
	@include SP {
		margin-bottom: 60px;
	}
	&__inner {
		@include PC {
		    padding: 60px 40px 30px;
		}
		@include SP {
			padding-top: 50px;
			padding-bottom: 10px;
		}

	}
	&:before {
		@include gradient-adva;
    	@include absolute(calc(1000px + (100% - 1000px)/2 ),235px,-70px,'',0,0);
    	border-radius: 0px 20px 0 0px;
    	z-index: 200;
    	@include SP {
    		top: -35px;
			height: 256px;
			width: 80%;
    	}
	}
	.container {
		border-radius: 20px 0 20px 0;
		background: #fff;
		position: relative;
		z-index: 200;
		// @include PC {
		// 	&:after {
		// 		@include absolute(100%,100%,0,-100%,'','');
		// 		background-color: #fff;
		// 		z-index: -1;
		// 	}	
		// }
		@include SP {
			margin-left: 5px;
			margin-right: 5px;
		}
	}
	&__head {
		@include PC {
			width: 175px;
		}
		@include SP {
			text-align: center;
			margin-bottom: 25px;
		}
		.txt {
			@include brandon;
			font-weight: bold;
			@include font-size(54);
			letter-spacing: 0.1em;
			color: #2ea3ed;
			line-height: 1;
			@include SP {
				@include font-size(34);
			}
		}
		.head {
			@include font-size(20);
			font-weight: 500;
			@include SP {
				@include font-size(16);
			}
		}
	}
	&__ul{
		@include PC {
			width: calc(100% - 215px);
		}
		@include font-size(14);
		li {
			margin-bottom: 20px;
			@include SP {
				margin-bottom: 15px;
			}
			span {
				@include PC {
					width: 110px;
				}
				display: block;
				@include brandon;
				@include SP {
					margin-bottom: 5px;
				}
			}
			.cmsContent {
				@include PC {
					width: calc(100% - 110px);
				}
			}
			a {
				color: #2EA3ED;
				text-decoration: underline;
			}
		}
	}
	.linknews {
		@include PC {
			margin-right: -25px;
			margin-top: -10px;
		}
		.btn-common { 
			@include PC{
				margin-right: 0; 
			}
			@include SP{
				margin-top: 10px;
			}
		}
	}
}

.com-rec {
	@include SP {
		position: relative;
		&:after {
		    content: '';
		    position: absolute;
		    background: #f2faff;
		    top: 0;
		    width: 40px; 
		    height: 100%;
		    z-index: -1; 
		}
	}
	a {
		display: block;
		color: #FFF;
		@include PC {
			width: 50%;
			transition: 0.3s;
		}
		.txt {
			@include brandon;
			font-weight: bold;
			@include font-size(54);
			letter-spacing: 0.1em;
			line-height: 1;
			@include SP {
				@include font-size(34);
			}
		}
		.head {
			@include font-size(20);
			font-weight: 500;
			margin-top: 20px;
			@include SP {
				margin-top: 5px;
				@include font-size(16);
			}
		}
		position: relative;
		box-shadow: 0px 0px 10px rgba(68, 120, 165, 0.16);
		@include HOVER {
			&:hover {
				box-shadow: none;
			}
		}
		&:first-child{
			background: url(../../img/top/img_top02.jpg);
			background-size: cover;
			border-radius: 0 20px 0 0;
			@include SP {
				margin-right: 20px;
			} 
		}
		&:last-child{
			background: url(../../img/top/img_top03.jpg);
			background-size: cover;
			border-radius: 0 0 0 20px;
			position: relative;
			@include PC {
				bottom: -70px;
			} 
			@include SP {
				margin-left: 20px;
			}
		}
	}
	&__inner {
		position: absolute;
		top: 50%;
		left: 50%;
		@include transform_c(translate(-50%,-50%));
		text-align: center;
		width: 100%;
	}
	
}
